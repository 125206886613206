import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`I am a Site Reliability Engineer at `}<a href="https://engineering.linkedin.com" target="_blank" rel="noopener noreferrer" title="LinkedIn">{`LinkedIn`}</a>{`, working in the Data Infrastructure SRE team.`}</p>
    <p>{`Know more about me at `}<a href="https://github.com/nirantak" target="_blank" rel="noopener noreferrer" title="GitHub">{`GitHub`}</a>{`/`}<a href="https://www.linkedin.com/in/nirantak" target="_blank" rel="noopener noreferrer" title="LinkedIn">{`LinkedIn`}</a>{` or find links to other platforms `}<a href="https://nirantak.com" target="_blank" rel="noopener noreferrer" title="nirantak.com">{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      