import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Hue API 💡 " link="https://hue-api.nirantak.com" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  A python package that provides an async API and CLI for controlling Hue Lights
    </ProjectCard>
    <ProjectCard title="Home Automation 🏠 " link="https://github.com/nirantak/home-automation" bg="linear-gradient(to right, #D4145A 0%, #FBA13B 100%)" mdxType="ProjectCard">
  A set of tools for automating smart home devices
    </ProjectCard>
    <ProjectCard title="Dotfiles ⚙️ " link="https://github.com/nirantak/dotfiles" bg="linear-gradient(90deg, #EAAE2D 0%, #3A1C71 100%)" mdxType="ProjectCard">
  Setup scripts and configuration files for Mac and Linux
    </ProjectCard>
    <ProjectCard title="Infra Automation 🤖" link="https://github.com/nirantak/infra-automation" bg="linear-gradient(to right, #007236 0%, #F1E420 100%)" mdxType="ProjectCard">
  Ansible playbooks to automate setup of dev environments and home servers
    </ProjectCard>
    <ProjectCard title="Web Scraper 🕸 " link="https://github.com/nirantak/scraper" bg="linear-gradient(90deg, #F0B129 0%, #21B1B3 100%)" mdxType="ProjectCard">
  Web scraping scripts written in Python to collect data from websites
    </ProjectCard>
    <ProjectCard title="CopyTables 📄 " link="https://addons.mozilla.org/addon/copywebtables/" bg="linear-gradient(to right, #C75CFF 0%, #00E1D2 100%)" mdxType="ProjectCard">
  A firefox extension that allows you to copy HTML tables from websites in
  various formats like rich text, HTML, CSV and TSV
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      